<template>
    <div class="page-wrapper adminanalytics-wrap">
        <div class="container-fluid">
            <div class="page-title">
                <h1>Admin Analytics</h1>
                <div class="aa-filter-div">
                    <select>
                    <option>OVERALL</option>
                    <option>REPS</option>
                    <option>GROUPS</option>
                    <option>ENROLLMENTS</option>
                    <option>AVG PREMIUMS</option>
                    <option>MEDICAL BREAKDOWN</option>
                    <option>SA BREAKDOWN</option>
                    <option>RIDER BREAKDOWN</option>
                    <option>RX TOTALS</option>
                    <option>RX OVERALL</option>
                    <option>RX NCE OVERALL</option>
                    </select>
                </div>
                <div class="row aa-overall-wrapper text-center">
                    <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3">
                        <div class="adminanalytics-blockWrapper">
                            <div class="adminanalytics-block">
                                <div class="admin-analytics-content">
                                    <h3>2005</h3>
                                    <span class="top-span">Reps</span>
                                    <p>States: 46</p>
                                    <span class="sub-span">NO REPS: IA, ND, SD, VT,</span>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3">
                        <div class="adminanalytics-blockWrapper">
                            <div class="adminanalytics-block">
                                <div class="admin-analytics-content">
                                    <h3>544</h3>
                                    <span class="top-span">EMPLOYERS & GROUPS</span>
                                    <ul class="aa-eg-ul">
                                        <li><span>States</span><br>42</li>
                                        <li><span>Groups 5-9</span><br>85</li>
                                        <li><span>Groups 1</span><br>162</li>
                                        <li><span>Groups 10-49</span><br>51</li>
                                        <li><span>Groups 2-4</span><br>239</li>
                                        <li><span>Groups 50+</span><br>7</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3">
                        <div class="adminanalytics-blockWrapper">
                            <div class="adminanalytics-block">
                                <div class="admin-analytics-content">
                                    <h3>16608</h3>
                                    <span class="top-span">MEMBERS</span>
                                    <p>% M: 45.18</p>
                                    <p>% F: 54.82</p>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3">
                        <div class="adminanalytics-blockWrapper">
                            <div class="adminanalytics-block">
                                <div class="admin-analytics-content">
                                    <h3>52.6</h3>
                                    <span class="top-span">AVG AGE</span>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3">
                        <div class="adminanalytics-blockWrapper">
                            <div class="adminanalytics-block">
                                <div class="admin-analytics-content">
                                    <h3>18824</h3>
                                    <span class="top-span">POLICIES</span>
                                    <p><a href="#">Medical: 15458</a></p>
                                    <p><a href="#">SA:1359/1150</a></p>
                                    <p><a href="#">Riders:2007/1538</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3">
                        <div class="adminanalytics-blockWrapper">
                            <div class="adminanalytics-block">
                                <div class="admin-analytics-content">
                                    <h3>50</h3>
                                    <span class="top-span">STATES</span>
                                    <p>Members</p>
                                    <span class="sub-span">NO MEMBERS: ND,</span>
                                </div>
                            </div>
                        </div>
                    </div>
 
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="new-table-card">
                            <h3>NEW MEMBERS BY MONTH (ACTIVE & TERMED)</h3>
                            <div class="table-responsive">
                                <div class="data-year-div">
                                    <span class="data-year">2022</span>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>JAN</th>
                                            <th>FEB</th>
                                            <th>MAR</th>
                                            <th>APR</th>
                                            <th>MAY</th>
                                            <th>JUN</th>
                                            <th>JUL</th>
                                            <th>AUG</th>
                                            <th>SEPT</th>
                                            <th>OCT</th>
                                            <th>NOV</th>
                                            <th>DEC</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="active">ACTIVE</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="termed">TERMED</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="withdrawn">WITHDRAWN</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="net">NET</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div class="data-year-div">
                                    <span class="data-year">2021</span>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>JAN</th>
                                            <th>FEB</th>
                                            <th>MAR</th>
                                            <th>APR</th>
                                            <th>MAY</th>
                                            <th>JUN</th>
                                            <th>JUL</th>
                                            <th>AUG</th>
                                            <th>SEPT</th>
                                            <th>OCT</th>
                                            <th>NOV</th>
                                            <th>DEC</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="active">ACTIVE</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="termed">TERMED</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="withdrawn">WITHDRAWN</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="net">NET</span>
                                            </td>
                                            <td>
                                                <span class="jan">0</span>
                                            </td>
                                            <td>
                                                <span class="feb">0</span>
                                            </td>
                                            <td>
                                                <span class="mar">0</span>
                                            </td>
                                            <td>
                                                <span class="apr">0</span>
                                            </td>
                                            <td>
                                                <span class="may">0</span>
                                            </td>
                                            <td>
                                                <span class="jun">0</span>
                                            </td>
                                            <td>
                                                <span class="jul">0</span>
                                            </td>
                                            <td>
                                                <span class="aug">0</span>
                                            </td>
                                            <td>
                                                <span class="sept">0</span>
                                            </td>
                                            <td>
                                                <span class="oct">0</span>
                                            </td>
                                            <td>
                                                <span class="nov">0</span>
                                            </td>
                                            <td>
                                                <span class="dec">0</span>
                                            </td>
                                            <td>
                                                <span class="total">0</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AdminAnalytics",
}
</script>
<style src="@/assets/css/adminanalytics.css"></style>