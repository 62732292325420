var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper adminanalytics-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Admin Analytics")]), _c('div', {
    staticClass: "aa-filter-div"
  }, [_c('select', [_c('option', [_vm._v("OVERALL")]), _c('option', [_vm._v("REPS")]), _c('option', [_vm._v("GROUPS")]), _c('option', [_vm._v("ENROLLMENTS")]), _c('option', [_vm._v("AVG PREMIUMS")]), _c('option', [_vm._v("MEDICAL BREAKDOWN")]), _c('option', [_vm._v("SA BREAKDOWN")]), _c('option', [_vm._v("RIDER BREAKDOWN")]), _c('option', [_vm._v("RX TOTALS")]), _c('option', [_vm._v("RX OVERALL")]), _c('option', [_vm._v("RX NCE OVERALL")])])]), _c('div', {
    staticClass: "row aa-overall-wrapper text-center"
  }, [_c('div', {
    staticClass: "col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "adminanalytics-blockWrapper"
  }, [_c('div', {
    staticClass: "adminanalytics-block"
  }, [_c('div', {
    staticClass: "admin-analytics-content"
  }, [_c('h3', [_vm._v("2005")]), _c('span', {
    staticClass: "top-span"
  }, [_vm._v("Reps")]), _c('p', [_vm._v("States: 46")]), _c('span', {
    staticClass: "sub-span"
  }, [_vm._v("NO REPS: IA, ND, SD, VT,")])])])])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "adminanalytics-blockWrapper"
  }, [_c('div', {
    staticClass: "adminanalytics-block"
  }, [_c('div', {
    staticClass: "admin-analytics-content"
  }, [_c('h3', [_vm._v("544")]), _c('span', {
    staticClass: "top-span"
  }, [_vm._v("EMPLOYERS & GROUPS")]), _c('ul', {
    staticClass: "aa-eg-ul"
  }, [_c('li', [_c('span', [_vm._v("States")]), _c('br'), _vm._v("42")]), _c('li', [_c('span', [_vm._v("Groups 5-9")]), _c('br'), _vm._v("85")]), _c('li', [_c('span', [_vm._v("Groups 1")]), _c('br'), _vm._v("162")]), _c('li', [_c('span', [_vm._v("Groups 10-49")]), _c('br'), _vm._v("51")]), _c('li', [_c('span', [_vm._v("Groups 2-4")]), _c('br'), _vm._v("239")]), _c('li', [_c('span', [_vm._v("Groups 50+")]), _c('br'), _vm._v("7")])])])])])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "adminanalytics-blockWrapper"
  }, [_c('div', {
    staticClass: "adminanalytics-block"
  }, [_c('div', {
    staticClass: "admin-analytics-content"
  }, [_c('h3', [_vm._v("16608")]), _c('span', {
    staticClass: "top-span"
  }, [_vm._v("MEMBERS")]), _c('p', [_vm._v("% M: 45.18")]), _c('p', [_vm._v("% F: 54.82")])])])])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "adminanalytics-blockWrapper"
  }, [_c('div', {
    staticClass: "adminanalytics-block"
  }, [_c('div', {
    staticClass: "admin-analytics-content"
  }, [_c('h3', [_vm._v("52.6")]), _c('span', {
    staticClass: "top-span"
  }, [_vm._v("AVG AGE")])])])])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "adminanalytics-blockWrapper"
  }, [_c('div', {
    staticClass: "adminanalytics-block"
  }, [_c('div', {
    staticClass: "admin-analytics-content"
  }, [_c('h3', [_vm._v("18824")]), _c('span', {
    staticClass: "top-span"
  }, [_vm._v("POLICIES")]), _c('p', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Medical: 15458")])]), _c('p', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("SA:1359/1150")])]), _c('p', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Riders:2007/1538")])])])])])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3 col-md-4 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "adminanalytics-blockWrapper"
  }, [_c('div', {
    staticClass: "adminanalytics-block"
  }, [_c('div', {
    staticClass: "admin-analytics-content"
  }, [_c('h3', [_vm._v("50")]), _c('span', {
    staticClass: "top-span"
  }, [_vm._v("STATES")]), _c('p', [_vm._v("Members")]), _c('span', {
    staticClass: "sub-span"
  }, [_vm._v("NO MEMBERS: ND,")])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-3"
  }, [_c('div', {
    staticClass: "new-table-card"
  }, [_c('h3', [_vm._v("NEW MEMBERS BY MONTH (ACTIVE & TERMED)")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('div', {
    staticClass: "data-year-div"
  }, [_c('span', {
    staticClass: "data-year"
  }, [_vm._v("2022")])]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th'), _c('th', [_vm._v("JAN")]), _c('th', [_vm._v("FEB")]), _c('th', [_vm._v("MAR")]), _c('th', [_vm._v("APR")]), _c('th', [_vm._v("MAY")]), _c('th', [_vm._v("JUN")]), _c('th', [_vm._v("JUL")]), _c('th', [_vm._v("AUG")]), _c('th', [_vm._v("SEPT")]), _c('th', [_vm._v("OCT")]), _c('th', [_vm._v("NOV")]), _c('th', [_vm._v("DEC")]), _c('th', [_vm._v("TOTAL")])])]), _c('tbody', [_c('tr', [_c('td', [_c('span', {
    staticClass: "active"
  }, [_vm._v("ACTIVE")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "termed"
  }, [_vm._v("TERMED")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "withdrawn"
  }, [_vm._v("WITHDRAWN")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "net"
  }, [_vm._v("NET")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])])])]), _c('div', {
    staticClass: "data-year-div"
  }, [_c('span', {
    staticClass: "data-year"
  }, [_vm._v("2021")])]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th'), _c('th', [_vm._v("JAN")]), _c('th', [_vm._v("FEB")]), _c('th', [_vm._v("MAR")]), _c('th', [_vm._v("APR")]), _c('th', [_vm._v("MAY")]), _c('th', [_vm._v("JUN")]), _c('th', [_vm._v("JUL")]), _c('th', [_vm._v("AUG")]), _c('th', [_vm._v("SEPT")]), _c('th', [_vm._v("OCT")]), _c('th', [_vm._v("NOV")]), _c('th', [_vm._v("DEC")]), _c('th', [_vm._v("TOTAL")])])]), _c('tbody', [_c('tr', [_c('td', [_c('span', {
    staticClass: "active"
  }, [_vm._v("ACTIVE")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "termed"
  }, [_vm._v("TERMED")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "withdrawn"
  }, [_vm._v("WITHDRAWN")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "net"
  }, [_vm._v("NET")])]), _c('td', [_c('span', {
    staticClass: "jan"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "feb"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "mar"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "apr"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "may"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jun"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "jul"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "aug"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "sept"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "oct"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "nov"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "dec"
  }, [_vm._v("0")])]), _c('td', [_c('span', {
    staticClass: "total"
  }, [_vm._v("0")])])])])])])])])])])])]);
}]

export { render, staticRenderFns }